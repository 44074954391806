import React from 'react'
import propTypes from 'prop-types'

import { clsx } from 'clsx/lite'

import Font from './font'

/**
 * A TextBlock usually represents a text paragraph and should not be used inline.
 *
 * It can be rendered as other HTML elements like h1,h2,...
 *
 * Supports all features of the `<Font />` component.
 *
 * @example
 *
 * <TextBlock>
 * A normal paragraph
 * </TextBlock>
 * @example
 * <TextBlock as="h1">
 * A complex headline<br/>
 * <Font color="blue" size="normal">With an embedded subline</Font>
 * </TextBlock>
 * @example
 * <TextBlock family="heading" align="right" color="red">
 * Text blocks have alignment support.
 * </TextBlock>
 *
 * Inline formattings <TextBlock family="heading" italic color="red">are possible</TextBlock> as well.
 */
const TextBlock = (props) => (
  <Font
    {...props}
    className={clsx(props.className, props.align && `text-${props.align}`)}
  />
)

TextBlock.propTypes = {
  ...Font.propTypes,
  /**
   * Set the alignment of the text conent.
   *
   * Available options: https://developer.mozilla.org/en/docs/Web/CSS/text-align
   **/
  align: propTypes.string,
}

TextBlock.defaultProps = {
  ...Font.defaultProps,
  tag: 'p',
  align: null,
}

export default TextBlock
