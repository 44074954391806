import React from 'react'
import clsx from 'clsx/lite'

import { focusStyle } from './styles'

import './button.css'

const Button = ({ className, children, ...props }) => (
  <button {...props} className={clsx('button', focusStyle, className)}>
    {children}
  </button>
)

export default Button
