import React from 'react'
import clsx from 'clsx/lite'

import Link from '../mdx/link/link-renderer'

const MenuLink = (props) => (
  <Link
    {...props}
    className={clsx('block hover:underline', 'menu-link', props.className)}
  />
)

export default MenuLink
