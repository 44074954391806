import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { klona } from 'klona/json'

import pageConfig from '../page-config'

import MdxSuiteContextProvider from './contexts/provider'
import { BreakpointProvider } from './helpers/hooks/use-breakpoint'

import components from './components'

import IconsContext from './contexts/icons'
import icons from './icons'

export { onRouteUpdate } from './consent-manager'

// Inject required contexts
const App = ({ children }) => {
  const { themeConfig } = pageConfig

  const theme = themeConfig.theme.extend

  const contextConfig = klona(pageConfig)

  delete contextConfig.translations
  delete contextConfig.themeConfig
  delete contextConfig.mediaCollections

  return (
    <MdxSuiteContextProvider themeConfig={contextConfig}>
      <BreakpointProvider screens={theme.screens}>
        <MDXProvider components={components}>
          <IconsContext.Provider value={icons}>
            {children}
          </IconsContext.Provider>
        </MDXProvider>
      </BreakpointProvider>
    </MdxSuiteContextProvider>
  )
}

export default App
