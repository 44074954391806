import React from 'react'
import propTypes from 'prop-types'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import useImageDataFromContext from '../hooks/use-image-data-from-context'

// eslint-disable-next-line jsx-a11y/alt-text
const StaticImage = (props) => <img {...props} />

interface ImageProps {
  /**
   * Id of the internal image
   */
  id?: string;
  /**
   * URI of the external image
   */
  src?: string;
  /**
   * Alt text for the image.
   */
  alt?: string;
  /**
   * Should the image be rendered inline?
   */
  inline?: boolean;
  /**
   * Set the width of the image.
   */
  width?: string;
  /**
   * Set the height of the image.
   */
  height?: string;
  /**
   * Set how the image should be fit into the container.
   *
   * Possible options:
   * - fill
   * - contain
   * - cover
   * - none
   * - scale-down
   */
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  /**
   * Set how the image should be positioned within its container.
   */
  position?: string;
  /**
   * Defines which image variant / context is used to locate the image data.
   */
  contextKey?: string;
  /**
   * Specifies the loading behavior of the image.
   */
  loading?: 'eager' | 'lazy';
  imageData?: Queries.MdxSuiteContentfulAssetFragment
}

interface RenderData extends Queries.MdxSuiteContentfulAssetFragment {
  placeholder?: {
    dataURI: string
  }
  gatsbyImageData?: IGatsbyImageData
}


/**
 * Renders an image from an internal or external source.
 *
 * @example
 * # An internal image
 *
 * <Image id="randomImageId" width="300" />
 * @example
 * # An external image
 *
 * <Image src="https://source.unsplash.com/random" width="300" />
 */
export default function Image({
  id,
  contextKey,
  width,
  height,
  fit,
  position,
  src,
  alt,
  imageData,
  loading,
  inline,
  ...restProps
}: ImageProps) {
  const contextData = useImageDataFromContext({ id, contextKey })
  

  if (!id && !src && !imageData) {
    return 'image unavailable'
  }

  // Image propery construction
  const imgProps: React.ImgHTMLAttributes<HTMLImageElement> = {
    loading,
    ...restProps,
  }
  const imgStyle: React.CSSProperties = { display: inline ? 'inline-block' : 'block' }

  // Either trim alt or render empty for decorative images. See: https://www.w3.org/WAI/tutorials/images/decorative/
  if (alt && alt.trim && alt.trim()) {
    imgProps.alt = alt.trim()
  } else {
    imgProps.alt = ''
  }

  if (width) {
    imgStyle.width = width
  }
  if (height) {
    imgStyle.height = height
  }

  if (src) {
    return <StaticImage {...imgProps} style={imgStyle} src={src} />
  }

  const renderData: RenderData = imageData || contextData

  if (!renderData) {
    throw new Error(`Unable to render image: ${JSON.stringify(renderData)}`)
  }

  // The alt test should describe whats in the image: https://moz.com/learn/seo/alt-text
  imgProps.alt = renderData.description || renderData.title || imgProps.alt

  // The title is used as tooltip.
  // Will probably be removed as it comes with accessability problems:
  // https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Images_in_HTML#image_titles
  if (renderData.title) {
    imgProps.title = renderData.title
  }

  // custom placeholder support
  if (renderData.placeholder?.dataURI) {
    if (!renderData.gatsbyImageData?.placeholder) {
      renderData.gatsbyImageData.placeholder = {}
    }
    renderData.gatsbyImageData.placeholder.fallback =
      renderData.placeholder.dataURI
  }

  if (!renderData?.gatsbyImageData) {
    if (!renderData.url) {
      throw new Error(`Invalid image rendering data found for ${id}`)
    }
    return <StaticImage {...imgProps} style={imgStyle} src={renderData.url} />
  }

  return (
    // @ts-expect-error we enforce the alt to have a value, no worries TS
    <GatsbyImage
      {...imgProps}
      style={imgStyle}
      image={renderData.gatsbyImageData}
      objectFit={fit}
      objectPosition={position}
    />
  )
}

Image.displayName = 'Image'

Image.defaultProps = {
  contextKey: 'full',
  width: '100%',
  position: 'center center',
  loading: 'lazy',
  fit: 'contain',
  inline: false,
}

Image.propTypes = {
  /**
   * Id of the internal image
   */
  id: propTypes.string,
  /**
   * URI of the external image
   */
  src: propTypes.string,
  /**
   * Set the width of the image.
   *
   * https://developer.mozilla.org/en-US/docs/Web/CSS/width
   */
  alt: propTypes.string,
  /**
   * Should the image be rendered inline?
   *
   * https://developer.mozilla.org/en-US/docs/Web/CSS/display
   */
  inline: propTypes.bool,
  /**
   * Set the width of the image.
   *
   * https://developer.mozilla.org/en-US/docs/Web/CSS/width
   */
  width: propTypes.string,
  /**
   * Set the height of the image.
   *
   * https://developer.mozilla.org/en-US/docs/Web/CSS/height
   */
  height: propTypes.string,
  /**
   * Set how the image should be fit into the container.
   *
   * Possible options:
   *
   * * fill
   * * contain
   * * cover
   * * none
   * * scale-down
   *
   * Live demo and more details:
   * https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
   */
  fit: propTypes.string,
  /**
   * Set how the image should be positioned within its container.
   *
   * Takes two values, one for the horizontal and one for the vertical axis.
   *
   * Example values:
   *
   * * center bottom
   * * 2rem center
   * * top right
   *
   * Live demo and more details:
   * https://developer.mozilla.org/en-US/docs/Web/CSS/object-position
   */
  position: propTypes.string,
  /**
   * Defines which image variant / context is used to locate the image data.
   */
  contextKey: propTypes.string,
  loading: propTypes.oneOf(['eager', 'lazy']),
}
