import React from 'react'
import propTypes from 'prop-types'

/**
 * Reduces the width of the content column to a given value. Centers the content by default.
 *
 * @example
 * <Section>
 *
 * # Normal behavior:
 *
 * <Boxes><Box /><Box /></Boxes>
 *
 * # With `<NarrowSection />`:
 *
 * <NarrowSection>
 * <Boxes><Box /><Box /></Boxes>
 * </NarrowSection>
 * </Section>
 */
const NarrowSection = ({ children, justify, maxWidth }) => {
  return (
    <div className={`flex justify-${justify}`}>
      <div className="w-full max-w-none sm:max-w-80percent">{children}</div>
    </div>
  )
}

NarrowSection.propTypes = {
  children: propTypes.node.isRequired,
  justify: propTypes.string,
  maxWidth: propTypes.string,
}

NarrowSection.defaultProps = {
  justify: 'center',
}

export default NarrowSection
