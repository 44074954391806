import React from 'react'
import clsx from 'clsx/lite'

import Link from './link'

import './cta.css'

/**
 * Renders a Link styled as a call to action button.
 * @example
 * <CTA href="https://www.google.com/">CTA to google.com</CTA>
 * @example
 * <CTA id="randomPageId" />
 */
const CTA = (props) => (
  <Link className={clsx('cta', props.disbaled && 'disabled')} {...props} />
)

CTA.propTypes = Link.propTypes
CTA.defaultProps = Link.defaultProps

export default CTA
