import React, { useContext, useMemo } from 'react'
import propTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import clsx from 'clsx/lite'

import MdxSuiteContext from '../../contexts/mdx-suite'
import { findActiveTrail } from '../../helpers/menu'

import MenuUl from './menu-ul'
import MenuItem from './menu-item'

export default function MenuRecursive({ rootMenuItemId, className }) {
  const queryResult = useStaticQuery(graphql`
    query CustomMenuRecursiveQuery {
      allContentfulContentTypeMenuItem {
        nodes {
          ...MdxSuiteMenuItem
          subitems {
            ...MdxSuiteMenuItem
            subitems {
              ...MdxSuiteMenuItem
              subitems {
                ...MdxSuiteMenuItem
              }
            }
          }
        }
      }
    }
  `)

  const {
    pageContext: { pageId, locale: activeLocale, pageType },
    themeConfig: { pageTypeParentMenuItemIds },
  } = useContext(MdxSuiteContext)

  const menuRoot = queryResult.allContentfulContentTypeMenuItem.nodes.find(
    ({ sys: { locale, menuItemId } }) =>
      menuItemId === rootMenuItemId &&
      (!activeLocale || locale === activeLocale)
  )

  if (!menuRoot) {
    throw new Error(`Unable to locale root menu item ${rootMenuItemId}`)
  }

  const activeTrailId = useMemo(() => {
    const customPageId =
      pageTypeParentMenuItemIds && pageTypeParentMenuItemIds[pageType]
    return customPageId ?? pageId
  }, [pageTypeParentMenuItemIds, pageType, pageId])

  const activeTrail = useMemo(
    () =>
      findActiveTrail({
        id: activeTrailId,
        subTree: [menuRoot],
      }),
    [activeTrailId, menuRoot]
  )
  return (
    <RecursiveMenu
      children={menuRoot.subitems}
      activeTrail={activeTrail}
      className={className}
    />
  )
}

MenuRecursive.propTypes = {
  rootMenuItemId: propTypes.string.isRequired,
  className: propTypes.string,
}

function RecursiveMenu({ children, activeTrail, depth = 0, className }) {
  const depthClass = `depth-${depth}`

  return (
    <MenuUl className={clsx(className, depthClass)}>
      {children.map(({ ...itemData }) => (
        <MenuItem
          key={itemData.sys.menuItemId}
          activeTrail={activeTrail}
          className={depthClass}
          {...itemData}
        >
          {itemData.subitems && (
            <RecursiveMenu
              children={itemData.subitems}
              depth={depth + 1}
              activeTrail={activeTrail}
            />
          )}
        </MenuItem>
      ))}
    </MenuUl>
  )
}

RecursiveMenu.propTypes = {
  children: propTypes.array.isRequired,
  depth: propTypes.number,
  activeTrail: propTypes.array,
  className: propTypes.string,
}
