import React, { Suspense } from 'react'

import { PrivacyShield } from '@consent-manager/core'

import Loading from '../lazy/loading'

import './widget.css'

const Search = React.lazy(
  () => import(/* webpackChunkName: "search-widget" */ './index')
)

const searchIndices = [
  { name: `Pages`, title: `Pages`, hitComp: `PageHit` },
  {
    name: `BoardingSchools`,
    title: `Boarding Schools`,
    hitComp: `SchoolHit`,
  },
  {
    name: `SummerSchools`,
    title: `Summer Schools`,
    hitComp: `SchoolHit`,
  },
  {
    name: `BlogPosts`,
    title: `News`,
    hitComp: `PageHit`,
  },
]

export default function SearchWidget() {
  return (
    <div className="search-wrapper">
      <PrivacyShield id="algolia">
        <Suspense fallback={<Loading />}>
          <Search collapse indices={searchIndices} />
        </Suspense>
      </PrivacyShield>
    </div>
  )
}
