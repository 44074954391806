import React from 'react'
import propTypes from 'prop-types'

import Image from '../image'

import Overlap from '../overlap'
/**
 * Column to be placed in a `<Columns />` element.
 *
 * @example
 * <Columns maxColumns="2">
 * <ColumnOverlap backgroundImageId="randomPictureId">
 *
 * # Example text
 *
 * Default Layout.
 *
 * </ColumnOverlap>
 * <ColumnOverlap backgroundImageId="randomPictureId">
 *
 * # Example text
 *
 * Default Layout.
 *
 * </ColumnOverlap>
 * <ColumnOverlap backgroundImageId="randomPictureId">
 *
 * # Example Text
 *
 * Colored example.
 *
 * The quick brown fox jumps over the lazy dog
 *
 * </ColumnOverlap>
 * </Columns>
 */
const ColumnOverlap = ({ children, backgroundImageId, contextKey }) => {
  return (
    <Overlap
      image={
        backgroundImageId && (
          <Image id={backgroundImageId} contextKey={contextKey} fit="cover" />
        )
      }
    >
      {children}
    </Overlap>
  )
}

ColumnOverlap.defaultProps = {
  center: false,
  padding: null,
  colors: {},
  contextKey: 'third',
}

ColumnOverlap.propTypes = {
  children: propTypes.node,
  /** Overwrite specific colors */
  colors: propTypes.object,
  /** Internal background image id */
  backgroundImageId: propTypes.string,
  contextKey: propTypes.string,
}

export default ColumnOverlap
