import React from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import Image from '../image'

import './huge-overlap.css'

const HugeOverlap = ({ children, backgroundImageId, reverse }) => {
  return (
    <div className="huge-overlap-wrapper">
      {children && (
        <div className="md:relative md:h-full md:center-to-content-column">
          <div className={clsx('huge-overlap-content', reverse && 'reverse')}>
            {children}
          </div>
        </div>
      )}
      {backgroundImageId && (
        <div className="huge-overlap-background-image-wrapper">
          <Image id={backgroundImageId} fit="cover" contextKey="screen" />
        </div>
      )}
    </div>
  )
}

HugeOverlap.defaultProps = {
  reverse: false,
}

HugeOverlap.propTypes = {
  backgroundImageId: propTypes.node.isRequired,
  children: propTypes.node.isRequired,
  reverse: propTypes.bool,
}

export default HugeOverlap
