import React, { useMemo } from 'react'
import propTypes from 'prop-types'

import { useIntersection, useWindowSize } from 'react-use'

import DefaultLoading from './loading'
import useIsClient from '../../hooks/isClient'

/**
 * Ensure children are not rendered till the user scrolls close by
 */
export default function LazyComponent({
  children,
  Loading = DefaultLoading,
  forceRendering = false,
}) {
  const { isClient } = useIsClient()
  const { height } = useWindowSize()
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `${height}px 0px ${height}px 0px`,
    threshold: 1,
  })

  const shouldRender = useMemo(
    () => intersection && intersection.intersectionRatio,
    [intersection]
  )

  if (forceRendering) {
    return children
  }

  return (
    <div ref={intersectionRef}>
      {isClient && shouldRender ? children : <Loading />}
    </div>
  )
}

LazyComponent.defaultProps = {
  markers: false,
}

LazyComponent.propTypes = {
  /** The children that should not be rendered till the user scrolls close */
  children: propTypes.node.isRequired,
  /** Overwrite the default loading component */
  loading: propTypes.node,
  /** Do not listen for scroll position and force rendering. Useful for components that will get rendered above the fold. */
  forceRendering: propTypes.bool,
}
