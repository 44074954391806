import React from 'react'
import clsx from 'clsx/lite'

const MenuUl = (props) => (
  <ul
    {...props}
    className={clsx(
      'flex flex-nowrap m-0 p-0 list-none !mb-0',
      'menu-ul',
      props.className
    )}
  />
)

export default MenuUl
