import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Columns from './layout/columns'
import MdxSuiteContext from '../../contexts/mdx-suite'

import BlogPostTeaser from '../blog/blog-post-teaser'

// const BlogPostListingFooter = tw.div`my-8 text-center`

/**
 * A list of all blog posts of this site.
 *
 * @example <BlogPostListing />
 */
function BlogPostListing() {
  const {
    pageContext: { locale: activeLocale },
  } = useContext(MdxSuiteContext)

  const result = useStaticQuery(graphql`
    query NewsQuery {
      de: allContentfulContentTypeBlogPost(
        sort: { publicationDate: DESC }
        limit: 4
        filter: { sys: { locale: { eq: "de" } } }
      ) {
        nodes {
          sys {
            pageId: id
            locale: locale
          }
          title
          publicationDate
          image {
            ...MdxSuiteMediaCollectionHalf
          }
          content {
            childMdx {
              timeToRead
            }
          }
        }
      }
      en: allContentfulContentTypeBlogPost(
        sort: { publicationDate: DESC }
        limit: 4
        filter: {
          availableInEnglish: { eq: true }
          sys: { locale: { eq: "en" } }
        }
      ) {
        nodes {
          sys {
            pageId: id
            locale: locale
          }
          title
          publicationDate
          image {
            ...MdxSuiteMediaCollectionHalf
          }
          content {
            childMdx {
              timeToRead
            }
          }
        }
      }
    }
  `)

  const localizedResult =
    activeLocale === 'de' ? result.de.nodes : result.en.nodes

  const blogPosts = localizedResult.map((blogPost, i) => (
    <BlogPostTeaser blogPost={blogPost} key={i} hideOnDesktop={i > 1} />
  ))

  return (
    <>
      <Columns maxColumns={2}>{blogPosts}</Columns>
      {/* <BlogPostListingFooter>
        <CTA to={activeLocale === 'de' ? '/blog' : '/en/blog'}>
          {t('newsReadAll')}
        </CTA>
      </BlogPostListingFooter> */}
    </>
  )
}

export default BlogPostListing
