import React from 'react'
import propTypes from 'prop-types'

import './loading.css'

const LoadingPlaceholder = ({ labelText = 'Loading...' }) => {
  return (
    <div className="loading-wrapper">
      <div className="wave-wrapper">
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
      </div>
      <div className="mt-4">{labelText}</div>
    </div>
  )
}

LoadingPlaceholder.defaultProps = {}

LoadingPlaceholder.propTypes = {
  aspectRatio: propTypes.string,
}

export default LoadingPlaceholder
