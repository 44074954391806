import React, { useMemo } from 'react'

import { Messages } from '@consent-manager/interface-default'

import { Trans } from '@lingui/macro'

export const useMessages = (): Messages => {
  return useMemo(
    () => ({
      // General
      // 'consent-manager.close': () => t`close`,

      // Intro
      'consent-manager.introduction.title': () => (
        <Trans>Data protection enabled</Trans>
      ),
      'consent-manager.introduction.description': () => (
        <Trans>
          Some Website features are disabled to protect your privacy.
        </Trans>
      ),
      'consent-manager.introduction.learn-more': () => (
        <Trans>Learn more</Trans>
      ),
      'consent-manager.introduction.enable-all': () => (
        <Trans>Enable all features</Trans>
      ),

      // Form
      'consent-manager.form.headline': () => (
        <Trans>Website Features and Cookies</Trans>
      ),
      'consent-manager.form.description': () => (
        <Trans>
          <p>
            By default third party features are disabled to protect your
            privacy.
          </p>
          <p>
            To view third-party content, you first have to accept their specific
            terms and conditions. This includes their cookie policies, which can
            change anytime and which we have no control over.
            <br />
            But if you do not view this content, no third-party cookies are
            installed on your device.
          </p>
          <p>
            By activating the features you agree to the providers' terms of use
            and their cookie policy. You can opt out at any time.
          </p>
        </Trans>
      ),
      'consent-manager.form.reset': () => <Trans>reset defaults</Trans>,
      'consent-manager.form.enable-all': () => (
        <Trans>enable all features</Trans>
      ),
      'consent-manager.form.disable-all': () => (
        <Trans>disable all features</Trans>
      ),
      'consent-manager.form.save': () => <Trans>save and close</Trans>,

      // Fallback component
      'consent-manager.fallback.default.title': () => (
        <Trans>Recommended external content</Trans>
      ),
      'consent-manager.fallback.default.description': ({
        IntegrationLabel,
        title,
      }) => (
        <Trans
          components={{ IntegrationLabel: <IntegrationLabel /> }}
          values={{ title }}
        >
          <p>
            This feature contains content by <IntegrationLabel />
          </p>
          <p>
            To view this third-party content, you first have to accept their
            specific terms and conditions.
          </p>
          <p>
            This includes their cookie policies, which we have no control over.
          </p>
        </Trans>
      ),

      'consent-manager.fallback.default.enable': (values) => (
        <Trans values={values}>
          Enable {values.category} by {values.title}
        </Trans>
      ),
      'consent-manager.fallback.default.learn-more': () => (
        <Trans>Learn more</Trans>
      ),

      // Integration Default
      'consent-manager.integration.default.company': ({ IntegrationLabel }) => (
        <Trans components={{ IntegrationLabel: <IntegrationLabel /> }}>
          by <IntegrationLabel />
        </Trans>
      ),
      'consent-manager.integration.default.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.integration.default.title': ({ title }) => (
        <Trans>{title}</Trans>
      ),
      'consent-manager.integration.default.description': ({ description }) => (
        <Trans values={{ description }}>{description}</Trans>
      ),
      'consent-manager.integration.default.privacy-policy': ({
        Link,
        title,
      }) => (
        <Link>
          <Trans values={{ title }}>Privacy Policy by {title}</Trans>
        </Link>
      ),

      // Project specific overrides:
      'consent-manager.integration.algolia.description': ({
        description,
        PrivacyPolicyLink,
      }) => (
        <p>
          <Trans values={{ description }}>{description}</Trans>
          <br />
          <PrivacyPolicyLink />
        </p>
      ),
      'consent-manager.integration.algolia.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.integration.mapbox.description': ({
        description,
        PrivacyPolicyLink,
      }) => (
        <p>
          <Trans values={{ description }}>{description}</Trans>
          <br />
          <PrivacyPolicyLink />
        </p>
      ),
      'consent-manager.integration.mapbox.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.integration.matomo.description': ({
        description,
        PrivacyPolicyLink,
      }) => (
        <p>
          <Trans values={{ description }}>{description}</Trans>
          <br />
          <PrivacyPolicyLink />
        </p>
      ),
      'consent-manager.integration.matomo.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.integration.vimeo.description': ({
        description,
        PrivacyPolicyLink,
      }) => (
        <p>
          <Trans values={{ description }}>{description}</Trans>
          <br />
          <PrivacyPolicyLink />
        </p>
      ),
      'consent-manager.integration.vimeo.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.integration.youtube.description': ({
        description,
        PrivacyPolicyLink,
      }) => (
        <p>
          <Trans values={{ description }}>{description}</Trans>
          <br />
          <PrivacyPolicyLink />
        </p>
      ),
      'consent-manager.integration.youtube.category': ({ category }) => (
        <Trans>{category}</Trans>
      ),
      'consent-manager.fallback.mapbox.title': ({ title }) => (
        <Trans>{title}</Trans>
      ),
      'consent-manager.fallback.algolia.title': ({ title }) => (
        <Trans>{title}</Trans>
      ),
      'consent-manager.fallback.youtube.title': ({ title }) => (
        <Trans>{title}</Trans>
      ),
      'consent-manager.fallback.vimeo.title': ({ title }) => (
        <Trans>{title}</Trans>
      ),
    }),
    []
  )
}
