import React from 'react'
import LoadingPlaceholder from '../lazy/loading'

const BookingForm = React.lazy(
  () => import(/* webpackChunkName: "booking-form" */ '../forms/booking-form')
)

const BookingFormWrapper = (props) => (
  <React.Suspense fallback={<LoadingPlaceholder />}>
    <BookingForm {...props} />
  </React.Suspense>
)

export default BookingFormWrapper
