import React, { useMemo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import { useBreakpoint } from '../../../helpers/hooks/use-breakpoint'

import './columns.css'

/**
 * Display content next to each other.
 *
 * **Note:**
 *
 * If you need to use multiple elements within a column or want control over colors,
 * you should use the `<Column/>` element to wrap a single column.
 *
 *
 * @example
 * <Columns>
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * </Columns>
 * @example
 * <Columns maxColumns="2">
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * <Image id="randomImageId" />
 * </Columns>
 *
 * @example
 *
 * <Columns center>
 * <Column center>
 *
 * ## Some Content
 *
 * Just as a demo
 *
 * A very long one
 *
 * To get some extra lines
 *
 * </Column>
 * <Column center>
 * <Image id="randomImageId" />
 * </Column>
 * </Columns>
 */
export default function Columns({
  children,
  minColumns,
  maxColumns,
  reverseAt,
  center,
  ...props
}) {
  const originalColumns = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  )
  const [columns, setColumns] = useState(originalColumns)

  const breakpoints = useBreakpoint()

  // Set max columns based on number of columns
  const realMaxColumns = useMemo(() => {
    const desiredColumns =
      parseInt(maxColumns) > 0 ? parseInt(maxColumns) : columns.length
    if (desiredColumns > 12) {
      return 12
    }
    return desiredColumns
  }, [maxColumns, columns.length])

  // Reverse columns based on reverseAt
  useEffect(() => {
    if (breakpoints[reverseAt]) {
      setColumns(originalColumns.slice().reverse())
      return
    }
    setColumns(originalColumns.slice())
  }, [breakpoints, reverseAt, originalColumns])

  if (!originalColumns.length) {
    return null
  }

  return (
    <div
      className={clsx(
        'columns',
        realMaxColumns > 1 && `max-columns-${realMaxColumns}`,
        center && 'center'
      )}
      {...props}
    >
      {columns}
    </div>
  )
}

Columns.displayName = 'Columns'

Columns.defaultProps = {
  center: false,
  minColumns: 1,
}

Columns.propTypes = {
  children: propTypes.node.isRequired,
  /** Minimum number of columns for the smallest screen. */
  minColumns: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Maximum number of columns for the biggest screen. Defaults to number of items. Maximum is 12. */
  maxColumns: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Reverse the order of all columns as soon given screen size is reached */
  reverseAt: propTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /** Center text content */
  center: propTypes.bool,
}
