import React from 'react'
import propTypes from 'prop-types'

import { clsx } from 'clsx/lite'

import TextBlock from './text-block'

const responsiveSizes = {
  '2xl': 'responsive-font-2xl',
  xl: 'responsive-font-xl',
  lg: 'responsive-font-lg',
  md: 'responsive-font-md',
  sm: 'responsive-font-sm',
}

/**
 * Very bold version of <Claim />
 *
 * Supports all features of the `<TextBlock />` component.
 *
 * @example
 * <Claim>
 *
 * This text will be huge
 *
 * </Claim>
 *
 * @example
 * <Claim responsiveSize="sm">
 *
 * This text will be smaller
 *
 * </Claim>
 */
const Claim = (props) => (
  <TextBlock
    {...props}
    className={clsx(
      props.className,
      props.responsiveSize && responsiveSizes[props.responsiveSize]
    )}
  />
)

Claim.propTypes = {
  ...TextBlock.propTypes,
  children: propTypes.node,
  /**
   * Set the dynamic font size. Supports the following sized: "sm, md, lg, xl, 2xl"
   **/
  responsiveSize: propTypes.string,
}

Claim.defaultProps = {
  ...TextBlock.defaultProps,
  responsiveSize: '2xl',
}

export default Claim
