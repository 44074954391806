import clsx from 'clsx/lite'

export const focusStyle = `focus:ring-secondary focus:border-secondary`

export const errorStyle = `border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500`

export const textFieldStyle = clsx(
  'shadow-sm block w-full sm:text-sm border border-gray-300 rounded-md px-3 form-input',
  focusStyle
)
