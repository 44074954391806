import React, { forwardRef } from 'react'
import clsx from 'clsx/lite'

const MenuLi = forwardRef((props, ref) => (
  <li
    {...props}
    ref={ref}
    className={clsx('px-4', 'menu-li', props.className)}
  />
))

export default MenuLi
