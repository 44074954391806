import React from 'react'
import {
  useTrackContactEvent,
  useTrackSocialMediaEvent,
} from '../hooks/tracking'
import Icon from './mdx/copy/icon'
import Link from './mdx/link/link'
import MenuLevel from './menu/menu-level'
import MenuRecursive from './menu/menu-recursive'
import clsx from 'clsx/lite'

import { t } from '@lingui/macro'

import { useLingui } from '@lingui/react'

const CopyRightMenu = ({ className }) => {
  const { i18n } = useLingui()
  return (
    <div className={className}>
      <p className="!mb-0">
        {t(i18n)`© Copyright ${new Date().getFullYear()}`} Stanford &amp; Ackel
      </p>
      <nav className={clsx('flex', 'md:inline-flex')}>
        <MenuLevel
          rootMenuItemId="3VwLVcNHulF5MghT11r3Xh"
          className="copyright-menu"
          level={0}
        />
      </nav>
    </div>
  )
}

export const Footer = () => {
  const { i18n } = useLingui()
  const trackContactEvent = useTrackContactEvent()
  const trackSocialMediaEvent = useTrackSocialMediaEvent()

  return (
    <footer className="mb-16 border-t border-secondary">
      <div
        className={clsx(
          'py-10 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center md:text-left',
          'center-to-content-column'
        )}
      >
        <section className="flex flex-col justify-between">
          <h1 aria-label="Stanford & Ackel" className="mb-4">
            <img
              src="/stanford-ackel-logo-full.svg"
              className="inline-block w-64"
              alt="Stanford &amp; Ackel"
              title="Stanford &amp; Ackel Logo"
              loading="lazy"
              fetchpriority="low"
              width="256"
              height="115"
            />
          </h1>
          <p className="text-lg my-2 leading-tight">{t(
            i18n
          )`We support and advise you in your choice of both boarding schools and summer schools in the UK.`}</p>
          <CopyRightMenu className="hidden md:block" />
        </section>
        <section>
          <div className="h-16">
            <h2 className="text-3xl font-medium" as="h2">
              {t(i18n)`Contact us`}
            </h2>
          </div>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t(i18n)`Address`}
          </h3>
          Stanford &amp; Ackel Educational Consultants
          <br />
          Widenmayerstr. 49
          <br />
          {t(i18n)`80538 Munich, Germany`}
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t(i18n)`Email`}
          </h3>
          <Link
            href="mailto:info@stanford-ackel.com"
            onClick={() => trackContactEvent('Email')}
            className="hover:text-secondary no-underline hover:underline"
          >
            info@stanford-ackel.com
          </Link>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t(i18n)`Telephone`}
          </h3>
          <Link
            href="tel:+498960013850"
            onClick={() => trackContactEvent('Phone')}
            className="hover:text-secondary no-underline hover:underline"
          >
            + 49 (0)89 60 01 38 50
          </Link>
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t(i18n)`Social Media`}
          </h3>
          <Link
            className="text-xl mr-4"
            href="https://www.facebook.com/stanford.ackel"
            openInNewTab
            onClick={() => trackSocialMediaEvent('Facebook')}
          >
            <Icon icon="facebook" title="Follow us on Facebook" />
          </Link>
          <Link
            className="text-xl"
            href="https://www.instagram.com/stanford_ackel/"
            openInNewTab
            onClick={() => trackSocialMediaEvent('Instagram')}
          >
            <Icon icon="instagram" title="Follow us on Instagram" />
          </Link>
        </section>
        <section className="hidden md:block md:col-span-2 lg:col-span-1">
          <div className="lg:h-16" />
          <h3
            className="text-base text-secondary uppercase font-medium font-body mt-2 !mb-0"
            as="h3"
          >
            {t(i18n)`Quick Links`}
          </h3>
          <nav className="quick-links-menu">
            <MenuRecursive rootMenuItemId="2G4dtP5yvtku3Z5S43WgXv" />
          </nav>
        </section>
        <section className="md:hidden">
          <CopyRightMenu />
        </section>
      </div>
    </footer>
  )
}
