import React from 'react'
import propTypes from 'prop-types'

/**
 * Centers anything inside of it to the horizontal center of the screen.
 *
 * @example
 * <Center>
 *
 * I sooo centered
 *
 * </Center>
 */
const Center = (props) => (
  <div className="my-0 mx-auto text-center" {...props} />
)

Center.propTypes = {
  children: propTypes.node.isRequired,
}

export default Center
