import React from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import Image from '../../image'

import './column.css'

/**
 * Column to be placed in a `<Columns />` element.
 *
 * @example
 * <Columns maxColumns="2">
 * <Column>
 *
 * # Example text
 *
 * Default Layout.
 *
 * </Column>
 * <Column>
 *
 * # Example text
 *
 * Default Layout.
 *
 * </Column>
 * <Column colorSet="blue">
 *
 * # Example Text
 *
 * Colored example.
 *
 * The quick brown fox jumps over the lazy dog
 *
 * </Column>
 * <Column colorSet="red">
 *
 * # Example Text
 *
 * Colored example.
 *
 * The quick brown fox jumps over the lazy dog
 *
 * </Column>
 * </Columns>
 * @example
 * <Columns>
 * <Column>
 *
 * # Little Content
 *
 * Content to the right is leading in height.
 *
 * </Column>
 * <Column>
 *
 * # Much Content
 *
 * Curabitizzle et fo shizzle fo nisi gangsta mollizzle. Suspendisse
 * fo. Own yo' odio. Sure neque. Crizzle orci. Crizzle mauris shit,
 * interdizzle a, feugiat sit amizzle, bizzle izzle, pede. Pellentesque
 * gizzle. Vestibulum for sure mi, **volutpat izzle**,
 * sagittizzle sizzle, bizzle sempizzle, velit. Crackalackin mah nizzle
 * justo et crazy. Nunc urna. Suspendisse dawg placerizzle stuff.
 * Curabitur daahng dawg ante.
 *
 * </Column>
 * <Column backgroundImage="https://source.unsplash.com/1600x900/?nature" />
 * </Columns>
 * @example
 * <Columns>
 * <Column>
 *
 * # Little Content
 *
 * Last image is leading in height due to minAspectRatio set to 9:16
 *
 * </Column>
 * <Column>
 *
 * # Content Image
 *
 * <Image src="https://source.unsplash.com/800x600/?water" />
 *
 * </Column>
 * <Column
 * backgroundImage="https://source.unsplash.com/900x1600/?people"
 * minAspectRatio="900 / 1600"
 * />
 * </Columns>
 * @example
 * <Columns>
 * <Column>
 *
 * # Little Content
 *
 * Center image has cubic min aspect ratio
 *
 * </Column>
 * <Column
 * backgroundImage="https://source.unsplash.com/800x600/?sunset"
 * minAspectRatio="800/800"
 * />
 * <Column>
 *
 * # Little Content
 *
 * </Column>
 * </Columns>
 * @example
 * <Columns>
 * <Column>
 *
 * # 100% content agnostic
 *
 * </Column>
 * <Column>
 *
 * # 100% content agnostic
 *
 * </Column>
 * <Column>
 *
 * # 100% content agnostic
 *
 * </Column>
 * </Columns>
 */
const Column = ({
  children,
  colorSet,
  minAspectRatio,
  backgroundImage,
  backgroundImageId,
  showAnimation,
  padding,
  center,
  ...columnProps
}) => {
  const hasBackground = !!colorSet || !!backgroundImageId || !!backgroundImage
  const contentPadding = padding || (hasBackground ? 'content-gap' : null)

  const columnContent = (
    <div
      className={clsx(
        'column',
        !!backgroundImage && 'has-background-image',
        colorSet && `bg-blue-100`
      )}
      style={{ backgroundImage: backgroundImage && `url(${backgroundImage})` }}
      {...columnProps}
    >
      {minAspectRatio && (
        <div
          className="spacer"
          style={{
            paddingTop: `calc(100% / (${minAspectRatio}))`,
          }}
        />
      )}
      {children && (
        <div
          className={clsx(
            'relative z-10 h-full',
            center && 'flex flex-col w-full justify-center'
          )}
        >
          <div className={clsx(contentPadding && `p-${contentPadding}`)}>
            {children}
          </div>
        </div>
      )}
      {backgroundImageId && (
        <Image
          className="absolute inset-0"
          id={backgroundImageId}
          fit="cover"
        />
      )}
    </div>
  )

  return columnContent
}

Column.defaultProps = {
  center: false,
  padding: null,
}

Column.propTypes = {
  children: propTypes.node,
  /** Define a color set for this box */
  colorSet: propTypes.string,
  /** Optional minimum aspect ratio. Either a mathematical expression or a number. ("16 / 9" or "1.5") */
  minAspectRatio: propTypes.string,
  /** External background image URI */
  backgroundImage: propTypes.string,
  /** Internal background image id */
  backgroundImageId: propTypes.string,
  /** Vertically center column content. For horizontal centering use <Center /> */
  center: propTypes.bool,
  /** Apply show animation */
  showAnimation: propTypes.string,
  /** Define the inner gab to the content */
  padding: propTypes.string,
}

export default Column
