import React, { useMemo } from 'react'
import propTypes from 'prop-types'
import Columns from './layout/columns'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'

/**
 * This is a workaround to keep old content working as expected. IGNORE THIS COMPONENT
 */
const OldColumns = ({ reverseAt = null, ...props }) => {
  const { theme } = useMemo(() => resolveConfig(tailwindConfig), [])
  const keys = Object.keys(theme.screens)
  let newReverseAt = reverseAt
  const key = parseInt(newReverseAt)
  if (!isNaN(key)) {
    newReverseAt = keys[key]
  }
  return <Columns reverseAt={newReverseAt} {...props} />
}

OldColumns.propTypes = {
  reverseAt: propTypes.oneOfType([propTypes.number, propTypes.string]),
}

export default OldColumns
