import React from 'react'
import propTypes from 'prop-types'

import './separator.css'

const Separator = ({ height, maxWidth, ...props }) => (
  <span
    className="separator"
    style={{
      lineHeight: height,
      height: height,
      maxWidth: maxWidth,
    }}
    {...props}
  />
)

Separator.defaultProps = {
  height: '4px',
  maxWidth: '61.8%',
}

Separator.propTypes = {
  height: propTypes.string,
  maxWidth: propTypes.string,
}

export default Separator
