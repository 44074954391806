import React from 'react'

import App from './src/app'
import Layout from './src/components/layout/layout'

export { onRouteUpdate } from './src/consent-manager'

// Inject required contexts
export const wrapRootElement = ({ element, ...props }) => (
  <App {...props}>{element}</App>
)

export const onClientEntry = async (...args) => {
  if (window.location.pathname.indexOf('ui-extensions') === -1) {
    return
  }

  console.log('Init CTF UI SDK')

  const ContenfulUISDK = await import('contentful-ui-extensions-sdk')

  // Init Contentful UI Extension SDK before the window load event
  ContenfulUISDK.init((sdk) => {
    console.log('contentful ui sdk initialized. waiting...')
    window.setTimeout(() => {
      const contentfulSdkEvent = new Event('contentfulSdkEvent')
      contentfulSdkEvent.sdk = sdk
      window.dispatchEvent(contentfulSdkEvent)
      console.log('sending event')
    }, 1000)
  })
}

export const wrapPageElement = ({ element, ...props }) => {
  return <Layout {...element.props}>{element}</Layout>
}
