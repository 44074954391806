import React from 'react'

const SectionHeader = ({ children }) => (
  <h2
    className="max-w-2xl pb-4 mx-auto mb-8 md:mt-4
      text-center
      font-headline font-normal text-gray-500 md:text-gray-400
      border-solid border-0 border-b border-gray-400
      responsive-font-xl"
    style={{ width: '61.8%' }}
  >
    {children}
  </h2>
)

export default SectionHeader
