import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import MdxSuiteContext from '../../contexts/mdx-suite'
import Icon from '../mdx/copy/icon'
import { generatePageMap, getPageWithFallback } from '../../helpers/routing'

import './language-switch.css'

export default function LanguageSwitch(props) {
  const {
    pageContext: { pageId, locale: activeLocale },
    themeConfig: { langs, defaultLocale },
  } = useContext(MdxSuiteContext)

  const result = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          ...MdxSuiteSitePageMetadata
        }
      }
    }
  `)

  // Generate a language based map of sub pages relating to the current content
  const pageMap = generatePageMap({
    pages: result.allSitePage.nodes,
    activePageId: pageId,
  })

  // Array representing the language switcher menu
  const langsMenu = langs
    .map((locale) => {
      const page = getPageWithFallback({ pageMap, locale, defaultLocale })
      if (!page) {
        return null
      }
      return {
        locale,
        page,
      }
    })
    .filter(Boolean)

  if (!langsMenu.length) {
    return null
  }

  return (
    <ul {...props} className="language-switch">
      {langsMenu.map(({ page, locale }) => (
        <li key={locale} hidden={locale === activeLocale}>
          {page && page.path && (
            <Link to={page.path} title={void `languageSwitch-${locale}`}>
              <Icon
                className="icon"
                icon={`flag-${locale}`}
                verticalAlign="middle"
              />
            </Link>
          )}
        </li>
      ))}
    </ul>
  )
}
