import { useMemo } from 'react'

export const formatSupportLineBreaks = (headline, supportRealLineBreaks) => {
  if (!headline) return ''

  // Support <wbr> and &shy;
  let shyHeadline = headline
    .replace(/<wbr[\s/]*>/g, '\u00AD')
    .replace(/&shy;/g, '\u00AD')

  if (supportRealLineBreaks) {
    // Support <br>
    shyHeadline = shyHeadline.replace(/<br[\s/]*>/g, '\n')
  }

  return shyHeadline
}

export const useFormatSupportLineBreaks = (
  headline,
  supportRealLineBreaks = false
) => {
  return useMemo(
    () => formatSupportLineBreaks(headline, supportRealLineBreaks),
    [headline, supportRealLineBreaks]
  )
}

export const formatRemoveLineBreaks = (headline) => {
  if (!headline) return ''

  return headline
    .replace(/<wbr[\s/]*>/g, '')
    .replace(/&shy;/g, '')
    .replace(/<br[\s/]*>/g, '')
}

export const useFormatRemoveLineBreaks = (headline) => {
  return useMemo(() => formatRemoveLineBreaks(headline), [headline])
}
