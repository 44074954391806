import React from 'react'
/**
 * This file integrates consent-manager to protect our visitors privacy
 * and supports us to align with GDPR and CCPA.
 *
 * Learn more: https://github.com/techboi/consent-manager
 */

import createPersistedState from 'use-persisted-state'

import {
  ConsentManagerConfig,
  ConsentManagerStorageState,
} from '@consent-manager/core'
import {
  matomoPrivacyAwareIntegration,
  getMatomoTracker,
} from '@consent-manager/integration-matomo'
import { mapboxIntegration } from '@consent-manager/integration-mapbox'
import { algoliaIntegration } from '@consent-manager/integration-algolia'
import { vimeoIntegration } from '@consent-manager/integration-vimeo'
import { youtubeIntegration } from '@consent-manager/integration-youtube'

import { ConsentManagerDefaultInterface } from '@consent-manager/interface-default'
import '@consent-manager/interface-default/dist/default.min.css'

import Button from './components/form/fields/button'

import components from './components'

import { useConsentManagerConfig } from './helpers/hooks/use-consent-manager-config'

import { useMessages } from './consent-manager-i18n'

import './consent-manager.css'

const useConsentStateStore =
  createPersistedState<ConsentManagerStorageState>('consent-manager')

const consentManagerConfig: ConsentManagerConfig = {
  integrations: [
    matomoPrivacyAwareIntegration({
      matomoURL: 'https://statistics.hashbite.net/',
      siteID: '13',
    }),
    mapboxIntegration(),
    algoliaIntegration(),
    vimeoIntegration(),
    youtubeIntegration(),
  ],
  onChangeDecision: (last, next) => {
    // console.log({ last, next })
    const { trackEvent } = getMatomoTracker()

    // Check if all got enabled/disabled
    let allTrue = true
    let allFalse = true

    for (const value of Object.values(next)) {
      if (value && allFalse) {
        allFalse = false
      }
      if (!value && allTrue) {
        allTrue = false
      }
    }
    if (allTrue) {
      // console.log('Enabled all')
      trackEvent && trackEvent('consent-manager', 'integration', 'all', 1)
      return
    }
    if (allFalse) {
      // console.log('Disabled all')
      trackEvent && trackEvent('consent-manager', 'integration', 'all', 0)
      return
    }

    // Detect single changes
    const diff = Object.keys(next).reduce(
      (diff, key) =>
        last[key] === next[key] ? diff : { ...diff, [key]: next[key] },
      {}
    )

    for (const [key, value] of Object.entries(diff)) {
      // console.log(`${value ? `Enabled` : `Disabled`} ${key}`)
      trackEvent &&
        trackEvent('consent-manager', 'integration', key, value ? 1 : 0)
    }
  },
}

/**
 * Wraps the apps root element with consent-manager
 * See:
 * * https://github.com/techboi/consent-manager
 * * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 */
export function ConsentManagerWrapper({ children }) {
  const storage = useConsentStateStore()
  const config = useConsentManagerConfig(components, consentManagerConfig)
  const messages = useMessages()

  return (
    <ConsentManagerDefaultInterface
      config={config}
      store={storage}
      Button={Button}
      messages={messages}
    >
      {children}
    </ConsentManagerDefaultInterface>
  )
}

/**
 * Called when the user changes routes, including on the initial load of the app
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 */
export function onRouteUpdate({ location, prevLocation }) {
  const { trackPageViewSPA } = getMatomoTracker()

  // This ensures plugins like react-helmet finished their work
  window.setTimeout(() => {
    const trackResult = trackPageViewSPA({ location, prevLocation })

    // Debug logging
    if (process.env.gatsby_log_level === `verbose` && trackResult) {
      const { url, title } = trackResult
      if (!trackResult) {
        return console.debug(
          `[Matomo] Failed to track page view: ${url} - ${title}`
        )
      }
      console.debug(`[Matomo] Page view for: ${url} - ${title}`)
    }
  }, 42)
}
