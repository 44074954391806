import { useMemo } from 'react'

import { getMatomoTracker } from '@consent-manager/integration-matomo'

export const useTrackContactEvent = () => {
  const { trackEvent } = useMemo(getMatomoTracker, [getMatomoTracker])

  return (type) => {
    trackEvent('Contact', type)
  }
}

export const useTrackSocialMediaEvent = () => {
  const { trackEvent } = useMemo(getMatomoTracker, [getMatomoTracker])

  return (type) => {
    trackEvent('Social Media', type)
  }
}
