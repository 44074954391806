const plugin = require('tailwindcss/plugin')

const merge = require('deepmerge')
const defaultTheme = require('./tailwind.smart.config.js')
const defaultTailwindTheme = require('./tailwind.default.config.js')

/**
 * Palette:
 * https://colorcolor.in/#('paletteParams':('steps':13,'paletteIndex':1,'swatchIndex':1,'params':~('hue':('start':229.75,'end':249.25,'ease':'linear'),'sat':('start':97,'end':100,'ease':'quadInOut'),'lig':('start':97,'end':4.5,'ease':'quadOut')),('hue':('start':49.75,'end':47.5,'ease':'quadIn'),'sat':('start':86.75,'end':90.25,'ease':'quadOut'),'lig':('start':100,'end':5,'ease':'quadOut')),('hue':('start':251.75,'end':239.5,'ease':'quadIn'),'sat':('start':0,'end':0,'ease':'sineInOut'),'lig':('start':95,'end':6.5,'ease':'quadOut'));),'settings':('overlayContrast':false,'overlayHex':true,'refColorsRaw':'#ffffff,#595959,#e6f0f2,#9f7c40,#37484e,#176fe3,#023074,#212730','colorSpace':'hsluv'))
 */
const blue = {
  100: '#f3faff',
  200: '#cfebfe',
  300: '#92d5fe',
  400: '#1db9f6',
  500: '#1293c9',
  600: '#086d9b',
  700: '#024a6f',
  800: '#0F3C5F',
  900: '#001023',
}
const gold = {
  100: '#fef5ef',
  200: '#fcddc0',
  300: '#f9ba68',
  400: '#da9a39',
  500: '#9e6b19',
  600: '#8c611f',
  700: '#6b4815',
  800: '#4d330b',
  900: '#321f05',
}
const gray = {
  100: '#f6f6f6',
  200: '#e0e0e0',
  300: '#c1c1c1',
  400: '#949494',
  500: '#747474',
  600: '#5f5f5f',
  700: '#444444',
  800: '#2b2b2b',
  900: '#161616',
}

// Extend default theme
const config = merge(defaultTheme, {
  mode: 'jit',
  content: [
    './src/components/**/*.{js,jsx,ts,tsx}',
    './src/pages/**/*.{js,jsx,ts,tsx}',
    './src/templates/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      spacing: {
        'grid-gap': defaultTailwindTheme.spacing[20],
        'content-gap': defaultTailwindTheme.spacing[6],
        'section-gap': defaultTailwindTheme.spacing[12],
        'content-column': '1280px',
        '80percent': '80%',
      },
      colors: {
        blue,
        gold,
        gray,
        primary: blue['700'],
        secondary: gold['500'],
        headline: blue['700'],
        'menu-link-active': blue['700'],
        'root-background': defaultTailwindTheme.colors.white,
        transparent: 'transparent',
        sets: {
          opaque: {
            background: defaultTailwindTheme.colors.white,
            text: defaultTailwindTheme.colors.text,
          },
          gray100: {
            background: defaultTailwindTheme.colors.gray['100'],
          },
          gray200: {
            background: defaultTailwindTheme.colors.gray['200'],
          },
          gray300: {
            background: defaultTailwindTheme.colors.gray['300'],
          },
          gray400: {
            background: defaultTailwindTheme.colors.gray['400'],
          },
          gray500: {
            background: defaultTailwindTheme.colors.gray['500'],
            text: defaultTailwindTheme.colors.white,
          },
          gray600: {
            background: defaultTailwindTheme.colors.gray['600'],
            text: defaultTailwindTheme.colors.white,
          },
          gray700: {
            background: defaultTailwindTheme.colors.gray['700'],
            text: defaultTailwindTheme.colors.white,
          },
          gray800: {
            background: defaultTailwindTheme.colors.gray['800'],
            text: defaultTailwindTheme.colors.white,
          },
          gray900: {
            background: defaultTailwindTheme.colors.gray['900'],
            text: defaultTailwindTheme.colors.white,
          },
        },
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({
      // strategy: 'base', // only generate global styles
      strategy: 'class', // only generate classes
    }),
    // Overwrite global html elements
    plugin(function ({ addBase, config }) {
      addBase({
        html: {
          lineHeight: config('theme.lineHeight.relaxed'),
        },
        a: {
          color: config('theme.colors.primary'),
          textDecoration: 'underline',
          textUnderlineOffset: '2px',
        },
        'a:focus, a:hover': {
          color: config('theme.colors.gold.600'),
          textDecoration: 'none',
          outline: 'none',
        },
        h1: {
          fontWeight: config('theme.fontWeight.medium'),
        },
        h2: {
          fontWeight: config('theme.fontWeight.medium'),
        },
        h3: {
          fontWeight: config('theme.fontWeight.medium'),
        },
        h4: {
          fontWeight: config('theme.fontWeight.medium'),
        },
      })
    }),
  ],
})

// Overwrite config values
config.theme.extend.fontFamily.headline = [
  'Lora',
  ...defaultTailwindTheme.fontFamily.serif,
]
config.theme.extend.fontFamily.serif = [
  'Lora',
  ...defaultTailwindTheme.fontFamily.serif,
]
config.theme.extend.fontFamily.body = [
  'Inter',
  ...defaultTailwindTheme.fontFamily.sans,
]

module.exports = config
