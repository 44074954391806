import { useStaticQuery, graphql, Link } from 'gatsby'

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import propTypes from 'prop-types'
import ScrollLock from 'react-scrolllock'
import clsx from 'clsx/lite'

import LanguageSwitch from './language-switch'
import Icon from '../mdx/copy/icon'
import MenuRecursive from '../menu/menu-recursive'
import SearchWidget from '../search/widget'

import { useTrackContactEvent } from '../../hooks/tracking'
import { useBreakpoint } from '../../helpers/hooks/use-breakpoint'

import './navigation-mobile.css'

import { t } from '@lingui/macro'

import { useLingui } from '@lingui/react'

const NavigationMobile = ({ logo, rootMenuItemId }) => {
  const { i18n } = useLingui()
  const trackContactEvent = useTrackContactEvent()
  const [menuOpen, setMenuOpen] = useState(false)

  const result = useStaticQuery(graphql`
    query StanfordAckelNavigationMobileQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [showSearch, setShowSearch] = useState(false)

  const handleClickMenuToggle = useCallback(
    () =>
      setMenuOpen((v) => {
        if (v) {
          setShowSearch(false)
        }
        return !v
      }),
    [setMenuOpen, setShowSearch]
  )

  const toggleSearch = useCallback(() => {
    setShowSearch((v) => !v)
    setMenuOpen((v) => !v)
  }, [setShowSearch])

  const breakpoints = useBreakpoint()

  // Disable scrolllock when switching to bigger screen
  useEffect(() => {
    if (breakpoints.lg) {
      setShowSearch(false)
      setMenuOpen(false)
    }
  }, [breakpoints])

  const showLogo = useMemo(
    () => !menuOpen || (menuOpen && showSearch),
    [menuOpen, showSearch]
  )

  return (
    <div className={clsx('navigation-mobile', menuOpen && 'open')}>
      <div className="navigation-mobile-top">
        <div className="flex justify-between items-center text-3xl center-to-content-column">
          {showLogo ? (
            <div className="logo-wrapper">
              <Link to="/" title={result.site.siteMetadata.title}>
                {logo}
              </Link>
            </div>
          ) : (
            <div className="flex items-center">
              <a
                href="mailto:info@stanford-ackel.com"
                onClick={() => trackContactEvent('Email')}
                className="flex items-center mr-8"
              >
                <Icon icon="mail" display="block" />
              </a>
              <a
                href="tel:+498960013850"
                onClick={() => trackContactEvent('Phone')}
                className="flex items-center mr-4"
              >
                <Icon icon="phone" display="block" />
              </a>
              <LanguageSwitch className="text-2xl" />
            </div>
          )}
          <div className="flex gap-2 items-center">
            {!menuOpen && (
              <div
                className="block p-2 text-2xl cursor-pointer"
                onClick={toggleSearch}
              >
                <Icon icon="search" display="block" />
              </div>
            )}
            <button
              className="xl:hidden p-2 border-none bg-transparent"
              onClick={handleClickMenuToggle}
              aria-label={t(i18n)`menu`}
            >
              <Icon icon={menuOpen ? 'close' : 'menu'} display="block" />
            </button>
          </div>
        </div>
      </div>
      <ScrollLock isActive={menuOpen}>
        <div className="navigation-mobile-bottom">
          <div className="navigation-mobile-bottom-content">
            {showSearch ? (
              <SearchWidget />
            ) : (
              <MenuRecursive rootMenuItemId={rootMenuItemId} />
            )}
          </div>
        </div>
      </ScrollLock>
    </div>
  )
}

NavigationMobile.defaultProps = {
  rootMenuItemId: '5yOQJ6BNP2pahfJzETReqe',
}

NavigationMobile.propTypes = {
  logo: propTypes.element.isRequired,
  rootMenuItemId: propTypes.string.isRequired,
}

export default NavigationMobile
