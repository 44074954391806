/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react'
import propTypes from 'prop-types'

import { clsx } from 'clsx/lite'

/**
 * Alter the font style of a partical part of a text or sentence.
 *
 * Should be used to layout within other paragraphs.
 *
 * To style a whole paragraph, please use the `<TextBlock/>` component.
 *
 * @example
 *
 * <Font family="heading" italic size="3" color="blue">
 * This will be display in the same font as headlines
 * </Font>
 *
 * Inline formattings <Font family="heading" italic color="red">are possible</Font> as well.
 */
const fontSizeClasses = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl leading-snug',
  '2xl': 'text-2xl leading-snug',
  '3xl': 'text-3xl leading-snug',
  '4xl': 'text-4xl leading-snug',
  '5xl': 'text-5xl leading-snug',
  '6xl': 'text-6xl leading-snug',
}

const lineHeightClasses = {
  none: 'leading-none',
  tight: 'leading-tight',
  snug: 'leading-snug',
  normal: 'leading-normal',
  relaxed: 'leading-relaxed',
  loose: 'leading-loose',
}

const Font = ({
  children,
  color,
  family,
  size,
  weight,
  italic,
  lineHeight,
  transform,
  tag,
  className,
}) => {
  return React.createElement(tag, {
    className: clsx(
      className,
      fontSizeClasses[size],
      lineHeightClasses[lineHeight],
      color && `text-${color}`,
      italic && 'italic',
      family && `font-${family}`,
      weight && `font-${weight}`,
      transform
    ),
    children,
  })
}

Font.propTypes = {
  children: propTypes.node.isRequired,
  /**
   * Set the font size.
   *
   * <Link to="/docs/theme/#font-sizes">List of all available font sizes</Link>
   **/
  size: propTypes.string,
  /**
   * Set the line height.
   *
   * <Link to="/docs/theme/#line-heights">List of all available font sizes</Link>
   **/
  lineHeight: propTypes.string,
  /**
   * Set color of text content.
   *
   * <Link to="/docs/theme/#color">List of all available colors</Link>
   */
  color: propTypes.string,
  /**
   * Align the text content.
   *
   * <Link to="/docs/theme/#font-family">List of all available font families</Link>
   */
  family: propTypes.string,
  /**
   * Set the font weight. Most fonts may not be available in uncommon font weights.
   *
   * **Hint**:
   *
   * You should prefer markdown (`i am **bold**`: i am **bold**) to mark single words or sentence fragments as bold.
   *
   * <Link to="/docs/theme/#font-weights">List of all available font widghts</Link>
   **/
  weight: propTypes.string,
  /**
   * Applies italic font style.
   *
   * **Hint**:
   *
   * You should prefer markdown (`i am *italic*`: i am *italic*) to mark single words or sentence fragments as italic.
   **/
  italic: propTypes.bool,
  /**
   * Applies css text transforms. Usually used to render text as UPPERCASE or Capitalized for styling reasons.
   *
   * **Hint**:
   *
   * Never directly write content text in UPPERCASE, always use this functionallity as these words can show up in search results.
   **/
  transform: propTypes.string,
  /**
   * Define the HTML element this text block is being rendered as.
   */
  tag: propTypes.string,
}

Font.defaultProps = {
  tag: 'span',
}

export default Font
