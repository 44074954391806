import React from 'react'
import propTypes from 'prop-types'

import './overlap.css'

const Overlap = ({ children, image }) => {
  return (
    <div className="relative mb-content-gap" style={{ paddingTop: '62%' }}>
      {children && (
        <div
          className="relative z-10
                    border-solid border-8 border-white
                    bg-root-background
                    p-6
                    shadow-sm my-0 mx-auto"
          style={{ width: '88%' }}
        >
          {children}
        </div>
      )}
      {image && <div className="background-image-wrapper">{image}</div>}
    </div>
  )
}

Overlap.defaultProps = {
  image: false,
  padding: null,
  colors: {},
}

Overlap.propTypes = {
  image: propTypes.node.isRequired,
  children: propTypes.node.isRequired,
}

export default Overlap
