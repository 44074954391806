import React from 'react'
import { Dialog } from '@headlessui/react'

import Icon from './icon'

export default function Popup({ children, isOpen, setIsOpen }) {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <Dialog.Backdrop
        className="fixed inset-0 z-30 bg-black/40"
        aria-hidden="true"
      />

      {/* Full-screen container to center the panel */}
      <div className="fixed z-50 inset-0 flex items-center justify-center">
        <div className="relative px-4 max-w-full" style={{ width: '42rem' }}>
          <div
            className="absolute top-0 right-0 z-50 -translate-y-1/2
                        p-3
                        cursor-pointer
                        text-gray-800 hover:text-gray-600
                        rounded-full
                        flex justify-center items-center"
            style={{
              background: 'var(--consent-manager-ui-toggle-bg)',
              filter: 'drop-shadow(var(--consent-manager-ui-drop-shadow))',
            }}
            onClick={() => setIsOpen(false)}
          >
            <Icon
              icon="close"
              display="block"
              svgProps={{ display: 'block' }}
            />
          </div>
          <Dialog.Panel
            className="w-full bg-white shadow-sm
                border-solid border-8 border-secondary
                p-4 md:p-8
                "
          >
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
