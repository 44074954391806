import React from 'react'
import propTypes from 'prop-types'
import clsx from 'clsx/lite'

import './list-item.css'

/**
 * Renders a list with an optional title and customizable bullet points.
 *
 * @example
 * <List>
 * <ListItem icon="check">Example for icon **check**</ListItem>
 * <ListItem icon="circle">Example for icon **circle**</ListItem>
 * <ListItem icon="dot">Example for icon **dot**</ListItem>
 * <ListItem icon="dotSmall">Example for icon **dotSmall**</ListItem>
 * <ListItem icon="star">Example for icon **star**</ListItem>
 * <ListItem icon="starOutlined">Example for icon **starOutlined**</ListItem>
 * </List>
 * @example
 * <List>
 * <ListItem icon="check" iconColor="#bada55">Colored example for icon **check**</ListItem>
 * <ListItem icon="circle" iconColor="blue">Colored example for icon **circle**</ListItem>
 * <ListItem icon="dot" iconColor="red-500">Colored example for icon **dot**</ListItem>
 * </List>
 */
const ListItem = ({ icon, iconColor, children, type, ...props }) => {
  if (!children) {
    return null
  }
  return (
    <li
      {...props}
      type={type}
      style={{ color: iconColor }}
      className={clsx('list-item', type === 'ordered' && 'ordered')}
    >
      {children}
    </li>
  )
}

ListItem.defaultProps = {
  type: 'unordered',
  icon: 'dot',
}

ListItem.propTypes = {
  children: propTypes.node,
  /** Overwrite the default icon set by the wrapping `<List/>` component. */
  icon: propTypes.string,
  /** Overwrite the default icon color set by the wrapping `<List/>` component. */
  iconColor: propTypes.string,
  /** Unordered lists use icons, ordered lists use a counter as bullet points. This will automatically be set by the wrapping `<List/>` component. */
  type: propTypes.oneOf(['ordered', 'unordered']),
}

export default ListItem
