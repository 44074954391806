import { useState, useEffect, startTransition } from 'react'

const useIsClient = () => {
  const [isClient, setClient] = useState(false)
  const key = isClient ? 'client' : 'server'

  useEffect(() => {
    startTransition(() => {
      setClient(true)
    })
  }, [])

  return { isClient, key }
}

export default useIsClient
