import React from 'react'

import defaultIcons from './default-icons'

import { FaGlobe } from '@react-icons/all-files/fa/FaGlobe'
import { FaTrain } from '@react-icons/all-files/fa/FaTrain'
import { FaPlane } from '@react-icons/all-files/fa/FaPlane'

import { HiDownload } from '@react-icons/all-files/hi/HiDownload'
import { HiExclamationCircle } from '@react-icons/all-files/hi/HiExclamationCircle'
import { HiBriefcase } from '@react-icons/all-files/hi/HiBriefcase'
import { HiMenu } from '@react-icons/all-files/hi/HiMenu'
import { HiExternalLink } from '@react-icons/all-files/hi/HiExternalLink'

import { SiMapbox } from '@react-icons/all-files/si/SiMapbox'
import { SiFacebook } from '@react-icons/all-files/si/SiFacebook'
import { SiInstagram } from '@react-icons/all-files/si/SiInstagram'
import { SiLinkedin } from '@react-icons/all-files/si/SiLinkedin'
import { SiYoutube } from '@react-icons/all-files/si/SiYoutube'
import { SiTwitter } from '@react-icons/all-files/si/SiTwitter'
import { SiVimeo } from '@react-icons/all-files/si/SiVimeo'

// Country flags via https://flagicons.lipis.dev/
import FlagDe from 'flag-icon-css/flags/1x1/de.svg'
import FlagEn from 'flag-icon-css/flags/1x1/gb.svg'

import IconArrow from './assets/icons/arrow.svg'
import IconClose from './assets/icons/close.svg'
import IconFilter from './assets/icons/filter.svg'
import IconGrid from './assets/icons/grid.svg'
import IconList from './assets/icons/list.svg'
import IconMail from './assets/icons/mail.svg'
import IconMap from './assets/icons/map.svg'
import IconPhone from './assets/icons/phone.svg'
import IconSchool from './assets/icons/school.svg'
import IconSearch from './assets/icons/search.svg'

const Dot = () => (
  <svg viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="6" fill="currentColor" />
  </svg>
)

const icons = new Map([
  // ...defaultIcons,
  // Project specific icons
  ...Object.entries({
    dot: { ...defaultIcons.get('dot'), scale: 0.8 },
    mail: { icon: IconMail },
    phone: { icon: IconPhone, scale: 0.8 },
    'flag-de': { icon: FlagDe },
    'flag-en': { icon: FlagEn },
    aircraft: { icon: FaPlane },
    globe: { icon: FaGlobe },
    school2: { icon: IconSchool },
    search: { icon: IconSearch },
    train: { icon: FaTrain },
    mapbox: { icon: SiMapbox },
    download: { icon: HiDownload },
    close: { icon: IconClose, scale: 0.8 },
    error: { icon: HiExclamationCircle },
    briefcase: { icon: HiBriefcase },
    arrow: { icon: IconArrow },
    filter: { icon: IconFilter },
    grid: { icon: IconGrid },
    list: { icon: IconList },
    map: { icon: IconMap },
    menu: { icon: HiMenu },
    facebook: { icon: SiFacebook },
    instagram: { icon: SiInstagram },
    youtube: { icon: SiYoutube },
    linkedin: { icon: SiLinkedin },
    twitter: { icon: SiTwitter },
    vimeo: { icon: SiVimeo },
    'external-link': { icon: HiExternalLink },
    dotSmall: { icon: Dot, scale: 0.6 },
  }),
])

export default icons
