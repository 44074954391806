import React from 'react'
import Image from '../image'

import './shadow-image.css'

const ShadowImage = (props) => (
  <div className="shadow-image-wrapper">
    <Image
      {...props}
      className="shadow-image"
      contextKey="half"
      fit="cover"
      loading="lazy"
      fetchpriority="low"
    />
  </div>
)

export default ShadowImage
